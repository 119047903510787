import { Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import './App.scss';
/*eslint-disable */

const Layout = React.lazy(() => import('./components/layout/Layout'));
const Home = React.lazy(() => import('./components/home/Home'));
const Tenq = React.lazy(() => import('./components/tenq/Tenq'));
const Dar = React.lazy(() => import('./components/dar/Dar'));

const App = () => {
  return (
    <Suspense>
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/tenq" element={<Tenq />} />
          <Route path="/dar" element={<Dar />} />
        </Route>
      </Routes>
    </div>
    </Suspense>
  );
};

export default App;
